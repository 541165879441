import Head from "next/head";
import React from "react";

const MetaHead = ({ ...props }) => {

	const prop = props;
	const data = {
		title: prop.title || "Mommiesdaily - Informasi Seputar Ibu, Anak, Pasangan, Pekerjaan",
		description: prop.description || "Temukan informasi terkini seputar kehamilan, anak, sekolah, pernikahan, lifestyle, kesehatan, liburan, hobby dan pengalaman mommies lainnya.",
		// keywords: prop.keywords || "Education,Mommies Working It,Parenting,Relationship",
		url: prop.url || process.env.NEXT_PUBLIC_ROOT_DOMAIN,
		// canonical: prop.canonical || process.env.NEXT_PUBLIC_ROOT_DOMAIN,
		author: prop.author || "Mommies Daily",
		publisher: prop.publisher || "Mommies Daily",
	};

	return (
		<Head>
			<title>
				{data.title}
			</title>

			{/* META SEO */}
			<link rel="canonical" href={data.url} />
			<meta name="description" content={data.description} />
			<meta name="author" content={data.author} />
			<meta name="publisher" content={data.publisher} />
			{/* <meta name="keywords" content={data.keywords} /> */}

			<meta property="og:description" content={data.description} />
			<meta property="og:url" content={data.url} />
		</Head>
	);

};

export default MetaHead;
